// 导航map
export const channeMap = {
  shein: { 
    '/': 1,
    '/men': 2,
    '/kids': 3,
    '/plussize': 4, 
    '/home': 5, 
    '/beauty': 20
  },
  romwe: {
    '/': 15,
    '/guys': 9
  }
}

// 搜索类型
export const SEARCH_TYPE_ENUM = {
  'EDIT_SEARCH': 2, // 用户原词搜索
  'DEFAULT_SEARCH': 3, // 底纹搜索词
  'SUGGESTION_SEARCH': 4, // 联想词
  'RECENT_SEARCH': 5, // 历史搜索词
  'HOT_SEARCH': 6, // 热门搜索词
  'SUGGESTION_SEARCH_CATEGORY': 7, // 联想品类词
  'SUGGESTION_SEARCH_FEEDBACK': 8, // 联想纠错词
  'HOT_SEARCH_PIC': 9, // 带图热搜词
  'LIST_FLOW': 10, // 信息流词
  'SEARCH_TREND': 11, // 趋势词
  'LIST_SEARCH': 12, // 列表内搜词
  'SEARCH_DISCOVERY': 13, // 推荐词
  'COMPOUND_SEARCH': 15, // 搜索组合词
  'LIST_SEARCH_RECOMMEND': 16, // 列表中推荐搜索词
  'SUGGSTION_ATTRI': 18, // 联想词标签
  'CORRECT_ORIGINAL_WORD': 19, // 纠错前的原词
  'RECOMMEND_SEARCH_POPUP': 21, // 搜索词点后推的搜索结果页
}

// 搜索来源
export const SEARCH_SOURCE_ENUM = {
  'USER_INPUT': 1, // 原词输入搜索，分布式筛选
  'ASSOCIATION': 2, // 联想词，含品类联想词和前置纠错联想词
  'DEFAULT_WORD': 3, // 底纹词
  'HOT_WORD': 4, // 推荐词，含搜索发现、图片热搜、信息流（原搜了又搜）词、列表页内搜索词
  'TREND_WORD': 5, // 趋势词
  'IMG_NAV': 15 // 图文导航
}

// 搜索服务包含筛选相关的key
export const ORIGIN_QUERY_FILTER_KEYS = [
  'child_cat_id',
  'attr_ids',
  'tag_ids',
  'min_price',
  'max_price',
]

// 搜索类型 => 搜索来源 映射
export const SEARCH_TYPE_2_SEARCH_SOURCE_MAP = {
  'EDIT_SEARCH': SEARCH_SOURCE_ENUM.USER_INPUT,
  'DEFAULT_SEARCH': SEARCH_SOURCE_ENUM.DEFAULT_WORD,
  'SUGGESTION_SEARCH': SEARCH_SOURCE_ENUM.ASSOCIATION,
  'RECENT_SEARCH': SEARCH_SOURCE_ENUM.USER_INPUT,
  'HOT_SEARCH': SEARCH_SOURCE_ENUM.HOT_WORD,
  'SUGGESTION_SEARCH_CATEGORY': SEARCH_SOURCE_ENUM.ASSOCIATION,
  'SUGGESTION_SEARCH_FEEDBACK': SEARCH_SOURCE_ENUM.ASSOCIATION,
  'HOT_SEARCH_PIC': SEARCH_SOURCE_ENUM.HOT_WORD,
  'LIST_FLOW': SEARCH_SOURCE_ENUM.HOT_WORD,
  'SEARCH_TREND': SEARCH_SOURCE_ENUM.TREND_WORD,
  'LIST_SEARCH': SEARCH_SOURCE_ENUM.HOT_WORD,
  'SEARCH_DISCOVERY': SEARCH_SOURCE_ENUM.HOT_WORD,
  'COMPOUND_SEARCH': SEARCH_SOURCE_ENUM.USER_INPUT,
  'SUGGSTION_ATTRI': SEARCH_SOURCE_ENUM.ASSOCIATION,
  'CORRECT_ORIGINAL_WORD': SEARCH_SOURCE_ENUM.USER_INPUT
}

export const getSearchSourceBySearchType = (searchType) => {
  const [searchTypeKey] = (Object.entries(SEARCH_TYPE_ENUM).filter(
    ([, value]) => value === Number(searchType)
  )[0] || [])
  if (searchTypeKey) return SEARCH_TYPE_2_SEARCH_SOURCE_MAP[searchTypeKey]
  return SEARCH_SOURCE_ENUM.USER_INPUT
}

// 找相似 白名单 仅一下页面进入预搜页展示找相似
export const SIMILAR_SEARCH_WHITE_LIST = [
  'page_goods_detail'
]

export const SEARCH_BORDER_ENHANCE_WHITE_LIST = [
  'config_index'
]
// 针对需求wiki.pageId=1096265082中新增的 category, Daily New, Flashsale, 优惠券中心处理跳转
export const TAB_MAP_LIST = { 
  '26': {
    url: '/category'
  },
  '16': {
    url: '/daily-new.html'
  },
  '37': {
    url: '/flash-sale.html'
  },
  '25': {
    url: '/user/coupon'
  }
}

export const SRC_MODULE_MAP = {
  all: 'search',
  store: 'store_search',
  brand: 'chanel_search',
  pageTrend: 'trend_search',
}
